import { useLocation } from '@reach/router';
import { isBrowser } from 'browser-or-node';
import { navigate } from 'gatsby';
import * as queryString from 'query-string';
import React, { useEffect } from 'react';

const InvitePage: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    if (isBrowser) {
      const { c } = queryString.parse(location.search);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      sessionStorage.setItem('REFERRAL_CODE', c as any);
      navigate('/account/join');
    }
  }, [location]);

  return <span></span>;
};

export default InvitePage;
